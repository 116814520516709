<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Amazon Virtual Assistant
                                <router-link to="/careers/amazon-virtual-assistant/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for an <b>Amazon Virtual Assistant</b> who can help with optimizing the partner's store in Amazon. The successful candidate must have a go-getter attitude and be persistent in accomplishing tasks, follow ups and other concerns. The ideal candidate must be well-organized, with good time management skills and work with minimal supervision.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the part. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Product research and competitor analysis</li>
                                            <li><i class="icon-ok"></i>Amazon account management, including comments and feedback from the customers for escalation</li>
                                            <li><i class="icon-ok"></i>Managing information flow in a timely and accurate manner</li>
                                            <li><i class="icon-ok"></i>Optimize product listings to attract traffic and brand awareness, including conduct of keyword research and creation of product page</li>
                                            <li><i class="icon-ok"></i>Source for suppliers across the globe</li>
                                            <li><i class="icon-ok"></i>Fulfill orders and respond to customer inquiries</li>
                                            <li><i class="icon-ok"></i>Assume special projects or duties as required</li>
                                            <li><i class="icon-ok"></i>Troubleshoot and resolve issues in a timely manner, ability to articulate errors and initiate processes to resolve issues</li>
                                            <li><i class="icon-ok"></i>Fix orphan listings, changing different sizes and colors from the parent template</li>
                                            <li><i class="icon-ok"></i>Add images, listing optimization, changing content, creating NIS templates</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Proven experience as an Amazon Virtual Assistant</li>
                                            <li><i class="icon-ok"></i>Ability to use correct listing guidelines is a must</li>
                                            <li><i class="icon-ok"></i>Familiarity with current technologies, like desktop sharing, cloud services and VoIP</li>
                                            <li><i class="icon-ok"></i>Experience with CRM, word-processing software, and spreadsheets (e.g. MS Office)</li>
                                            <li><i class="icon-ok"></i>Critical thinking capabilities, high technical aptitude and troubleshooting abilities.</li>
                                            <li><i class="icon-ok"></i>Excellent phone, email and instant messaging communication skills</li>
                                            <li><i class="icon-ok"></i>Excellent time management skills</li>
                                            <li><i class="icon-ok"></i>Experience with Helium 10 is an advantage</li>
                                            <li><i class="icon-ok"></i>Experience working on Amazon Central</li>
                                            <li><i class="icon-ok"></i>Solid organizational skills and keen attention to detail</li>
                                            <li><i class="icon-ok"></i>Good communication skills; verbal and written</li>
                                            <li><i class="icon-ok"></i>Experience using Shopify and WooCommerce is an advantage</li>
                                            <li><i class="icon-ok"></i>Knowledge and familiarity with shoes and intimate apparel (bras)</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/amazon-virtual-assistant/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>